.container {
  margin: 10px 20px;
}

input {
  margin: 8px 0;
}

label {
  font-weight: 700;
}

.title {
  margin-top: 10px;
}
